.app-icons {
  width: 16px !important;
  height: 16px !important;
  background-image: url(./images/icons.png) !important;
  margin: 5px 5px !important;
  padding-left: 16px !important;
  background-repeat: no-repeat;
  background-position-y: 5px !important;
  background-position-x: 16px !important;
}

.app-icon-0 {
  background-position: 0 0 !important;
}
.app-icon-1 {
  background-position: -16px 0 !important;
}
.app-icon-2 {
  background-position: -32px 0 !important;
}
.app-icon-3 {
  background-position: -48px 0 !important;
}
.app-icon-4 {
  background-position: -64px 0 !important;
}
.app-icon-5 {
  background-position: -80px 0 !important;
}
.app-icon-6 {
  background-position: -96px 0 !important;
}
.app-icon-7 {
  background-position: -112px 0 !important;
}
.app-icon-8 {
  background-position: -128px 0 !important;
}
.app-icon-9 {
  background-position: -144px 0 !important;
}
.app-icon-10 {
  background-position: -160px 0 !important;
}
.app-icon-11 {
  background-position: -176px 0 !important;
}
.app-icon-12 {
  background-position: -192px 0 !important;
}
.app-icon-13 {
  background-position: -208px 0 !important;
}
.app-icon-14 {
  background-position: -224px 0 !important;
}
.app-icon-15 {
  background-position: -240px 0 !important;
}
.app-icon-16 {
  background-position: -256px 0 !important;
}
.app-icon-17 {
  background-position: -272px 0 !important;
}
.app-icon-18 {
  background-position: -288px 0 !important;
}
.app-icon-19 {
  background-position: -304px 0 !important;
}
.app-icon-20 {
  background-position: -320px 0 !important;
}
.app-icon-21 {
  background-position: -336px 0 !important;
}
.app-icon-22 {
  background-position: -352px 0 !important;
}
.app-icon-23 {
  background-position: -368px 0 !important;
}
.app-icon-24 {
  background-position: -384px 0 !important;
}
.app-icon-25 {
  background-position: -400px 0 !important;
}
.app-icon-26 {
  background-position: -416px 0 !important;
}
.app-icon-27 {
  background-position: -432px 0 !important;
}
.app-icon-28 {
  background-position: -448px 0 !important;
}
.app-icon-29 {
  background-position: -464px 0 !important;
}
.app-icon-30 {
  background-position: -480px 0 !important;
}
.app-icon-31 {
  background-position: -496px 0 !important;
}
.app-icon-32 {
  background-position: -512px 0 !important;
}
.app-icon-33 {
  background-position: -528px 0 !important;
}
.app-icon-34 {
  background-position: -544px 0 !important;
}
.app-icon-35 {
  background-position: -560px 0 !important;
}
.app-icon-36 {
  background-position: -576px 0 !important;
}
.app-icon-37 {
  background-position: -592px 0 !important;
}
.app-icon-38 {
  background-position: -608px 0 !important;
}
.app-icon-39 {
  background-position: -624px 0 !important;
}
.app-icon-40 {
  background-position: -640px 0 !important;
}
.app-icon-41 {
  background-position: -656px 0 !important;
}
.app-icon-42 {
  background-position: -672px 0 !important;
}
.app-icon-43 {
  background-position: -688px 0 !important;
}
.app-icon-44 {
  background-position: -704px 0 !important;
}
.app-icon-45 {
  background-position: -720px 0 !important;
}
.app-icon-46 {
  background-position: -736px 0 !important;
}
.app-icon-47 {
  background-position: -752px 0 !important;
}
.app-icon-48 {
  background-position: -768px 0 !important;
}
.app-icon-49 {
  background-position: -784px 0 !important;
}
.app-icon-50 {
  background-position: -800px 0 !important;
}
.app-icon-51 {
  background-position: -816px 0 !important;
}
.app-icon-52 {
  background-position: -832px 0 !important;
}
.app-icon-53 {
  background-position: -848px 0 !important;
}
.app-icon-54 {
  background-position: -864px 0 !important;
}
.app-icon-55 {
  background-position: -880px 0 !important;
}
.app-icon-56 {
  background-position: -896px 0 !important;
}
.app-icon-57 {
  background-position: -912px 0 !important;
}
.app-icon-58 {
  background-position: -928px 0 !important;
}
.app-icon-59 {
  background-position: -944px 0 !important;
}
.app-icon-60 {
  background-position: -960px 0 !important;
}
.app-icon-61 {
  background-position: -976px 0 !important;
}
.app-icon-62 {
  background-position: -992px 0 !important;
}
.app-icon-63 {
  background-position: -1008px 0 !important;
}
.app-icon-64 {
  background-position: -1024px 0 !important;
}
.app-icon-65 {
  background-position: -1040px 0 !important;
}
.app-icon-66 {
  background-position: -1056px 0 !important;
}
.app-icon-67 {
  background-position: -1072px 0 !important;
}
.app-icon-68 {
  background-position: -1088px 0 !important;
}
.app-icon-69 {
  background-position: -1104px 0 !important;
}
.app-icon-70 {
  background-position: -1120px 0 !important;
}
.app-icon-71 {
  background-position: -1136px 0 !important;
}
.app-icon-72 {
  background-position: -1152px 0 !important;
}
.app-icon-73 {
  background-position: -1168px 0 !important;
}
.app-icon-74 {
  background-position: -1184px 0 !important;
}
.app-icon-75 {
  background-position: -1200px 0 !important;
}
.app-icon-76 {
  background-position: -1216px 0 !important;
}
.app-icon-77 {
  background-position: -1232px 0 !important;
}
.app-icon-78 {
  background-position: -1248px 0 !important;
}
.app-icon-79 {
  background-position: -1264px 0 !important;
}
.app-icon-80 {
  background-position: -1280px 0 !important;
}
.app-icon-81 {
  background-position: -1296px 0 !important;
}
.app-icon-82 {
  background-position: -1312px 0 !important;
}
.app-icon-83 {
  background-position: -1328px 0 !important;
}
.app-icon-84 {
  background-position: -1344px 0 !important;
}
.app-icon-85 {
  background-position: -1360px 0 !important;
}
.app-icon-86 {
  background-position: -1376px 0 !important;
}
.app-icon-87 {
  background-position: -1392px 0 !important;
}
.app-icon-88 {
  background-position: -1408px 0 !important;
}
.app-icon-89 {
  background-position: -1424px 0 !important;
}
.app-icon-90 {
  background-position: -1440px 0 !important;
}
.app-icon-91 {
  background-position: -1456px 0 !important;
}
.app-icon-92 {
  background-position: -1472px 0 !important;
}
.app-icon-93 {
  background-position: -1488px 0 !important;
}
.app-icon-94 {
  background-position: -1504px 0 !important;
}
.app-icon-95 {
  background-position: -1520px 0 !important;
}
.app-icon-96 {
  background-position: -1536px 0 !important;
}
.app-icon-97 {
  background-position: -1552px 0 !important;
}
.app-icon-98 {
  background-position: -1568px 0 !important;
}
.app-icon-99 {
  background-position: -1584px 0 !important;
}
.app-icon-100 {
  background-position: -1600px 0 !important;
}
.app-icon-101 {
  background-position: -1616px 0 !important;
}
.app-icon-102 {
  background-position: -1632px 0 !important;
}
.app-icon-103 {
  background-position: -1648px 0 !important;
}
.app-icon-104 {
  background-position: -1664px 0 !important;
}
.app-icon-105 {
  background-position: -1680px 0 !important;
}
.app-icon-106 {
  background-position: -1696px 0 !important;
}
.app-icon-107 {
  background-position: -1712px 0 !important;
}
.app-icon-108 {
  background-position: -1728px 0 !important;
}
.app-icon-109 {
  background-position: -1744px 0 !important;
}
.app-icon-110 {
  background-position: -1760px 0 !important;
}
.app-icon-111 {
  background-position: -1776px 0 !important;
}
.app-icon-112 {
  background-position: -1792px 0 !important;
}
.app-icon-113 {
  background-position: -1808px 0 !important;
}
.app-icon-114 {
  background-position: -1824px 0 !important;
}
.app-icon-115 {
  background-position: -1840px 0 !important;
}
.app-icon-116 {
  background-position: -1856px 0 !important;
}
.app-icon-117 {
  background-position: -1872px 0 !important;
}
.app-icon-118 {
  background-position: -1888px 0 !important;
}
.app-icon-119 {
  background-position: -1904px 0 !important;
}
.app-icon-120 {
  background-position: -1920px 0 !important;
}
.app-icon-121 {
  background-position: -1936px 0 !important;
}
.app-icon-122 {
  background-position: -1952px 0 !important;
}
.app-icon-123 {
  background-position: -1968px 0 !important;
}
.app-icon-124 {
  background-position: -1984px 0 !important;
}
.app-icon-125 {
  background-position: -2000px 0 !important;
}
.app-icon-126 {
  background-position: -2016px 0 !important;
}
.app-icon-127 {
  background-position: -2032px 0 !important;
}
.app-icon-128 {
  background-position: -2048px 0 !important;
}
.app-icon-129 {
  background-position: -2064px 0 !important;
}
.app-icon-130 {
  background-position: -2080px 0 !important;
}
.app-icon-131 {
  background-position: -2096px 0 !important;
}
.app-icon-132 {
  background-position: -2112px 0 !important;
}
.app-icon-133 {
  background-position: -2128px 0 !important;
}
.app-icon-134 {
  background-position: -2144px 0 !important;
}
.app-icon-135 {
  background-position: -2160px 0 !important;
}
.app-icon-136 {
  background-position: -2176px 0 !important;
}
.app-icon-137 {
  background-position: -2192px 0 !important;
}

.app-icons-top {
  background-position-y: 5px !important;
}

.ddm-tp {
  height: 250px;
  width: 100%;
  overflow: auto;
}
